<template>
  <div class="modal-contact-form">
    <mkp-image class="img-contact" :lazy-src="url" />

    <h1 class="mkp-contact-title">{{ $t("forms.contact.title") }}</h1>

    <form novalidate class="form-contact" @submit.prevent="submit">
      <mkp-name-component
        v-model="form.firstName"
        :v="$v.form.firstName"
        placeholder="forms.contact.firstname.placeholder"
        class="firstname-contact"
      />

      <mkp-name-component
        v-model="form.lastName"
        :v="$v.form.lastName"
        placeholder="forms.contact.lastname.placeholder"
        class="lastname-contact"
      />

      <mkp-email-component
        v-model="form.email"
        :v="$v.form.email"
        placeholder="forms.contact.email.placeholder"
        class="email-contact"
      />

      <mkp-area-component
        v-model="form.message"
        :v="$v.form.message"
        placeholder="forms.contact.message.placeholder"
        class="message-contact"
      />

      <mkp-button class="btn-send-message" @click="submit">
        {{ $t("forms.contact.button") }}
      </mkp-button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import MkpImage from "../../../simple/image/mkp-image";
import { required } from "vuelidate/lib/validators";
import MkpNameComponent from "../inputsForm/mkp-name-component";
import MkpEmailComponent from "../inputsForm/mkp-email-component";
import MkpAreaComponent from "../inputsForm/mkp-area-component";
import MkpButton from "../../../simple/button/mkp-button";
import "./mkp-contact-form.scss";
import { getValidBaseUrl } from "../../../../utils/dataValidator.js";

export default {
  name: "MkpContactForm",
  components: {
    MkpButton,
    MkpAreaComponent,
    MkpEmailComponent,
    MkpNameComponent,
    MkpImage
  },
  data: () => {
    return {
      url: require("./../../../../assets/images/sign-up.svg"),
      form: {
        email: null,
        firstName: null,
        lastName: null,
        message: null
      }
    };
  },
  validations: {
    form: {
      email: {
        required
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      message: {
        required
      }
    }
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return;
      // to form submit after this
      this.send();
    },
    send() {
      this.errors = [];
      let body = {
        contactUser: {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email
        },
        products: this.$store.getters.getCartContents,
        type: "support",
        description: {
          full: this.form.message
        }
      };
      axios
        .post(
          //process.env.VUE_APP_BASE_URL +
          getValidBaseUrl() + process.env.VUE_APP_BASE_URL_API + "/issues",
          body
        )
        .then(() => {
          this.$notify({
            group: "default",
            type: "notification",
            title: "Le message a bien été envoyé."
          });
          this.form.firstName = "";
          this.form.lastName = "";
          this.form.email = "";
          this.form.message = "";
          this.$store.commit("RESET_CARD");
          this.$store.dispatch("setModalState");
        })
        .catch(error => {
          this.$notify({
            group: "default",
            type: "error",
            title:
              "Le message n'a pas pu être envoyé. suite à l'erreur suivante: " +
              error
          });
          console.log(error);
        });
    }
  }
};
</script>

<style scoped></style>
