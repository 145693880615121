<template>
  <textarea
    :accessKey="accessKey"
    :autofocus="autofocus"
    :cols="cols"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :form="form"
    :maxLength="maxLength"
    :minLength="minLength"
    :name="name"
    :placeholder="placeholder"
    :readOnly="readOnly"
    :required="required"
    :rows="rows"
    :value="value"
    :style="style"
    :class="classes"
    @input="$emit('input', $event.target.value)"
    @click="$emit('click', $event)"
  ></textarea>
</template>

<script>
import "./mkp-text-area.scss";

export default {
  name: "MkpTextArea",
  props: {
    accessKey: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: null
    },
    cols: {
      type: Number,
      default: null
    },
    defaultValue: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: null
    },
    form: {
      type: Object,
      default: null
    },
    maxLength: {
      type: Number,
      default: null
    },
    minLength: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: null
    },
    required: {
      type: Boolean,
      default: null
    },
    rows: {
      type: Number,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    classes() {
      return {
        "mkp-textarea": true,
        "textarea-disable": false
      };
    },
    style() {
      return {};
    }
  }
};
</script>
