<template>
  <div :class="classes">
    <label class="label-area">{{ label }}</label>
    <mkp-text-area
      v-model="name"
      :placeholder="$t(placeholder)"
      autocomplete="on"
      class="message-textarea"
      @input="v.$touch()"
    />
    <div v-if="v.$error">
      <div
        v-if="v.required != undefined && !v.required"
        class="error-textearea"
      >
        {{ $t("forms.components.area.errors.required") }}
      </div>
    </div>
  </div>
</template>

<script>
import MkpTextArea from "../../../simple/text-area/mkp-text-area";
import "./inputsform.scss";
export default {
  name: "MkpAreaComponent",
  components: { MkpTextArea },
  props: {
    placeholder: {
      type: String,
      default: "forms.components.name.placeholder"
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    }
  },
  computed: {
    name: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    classes() {
      return {
        errorRed: this.v.$error
      };
    }
  }
};
</script>
